import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { getHeaders } from '../../request';
import Pesquisa from './pesquisa';
import Visualizacao from './visualizacao';
import { MODALIDADE } from '../../common/Constants';
import {
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import alerta from '../../signals/alerta';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const Aba = ({ ...other }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const gerandoPdf = useSignal(false);

    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    const imprimir = (idExame) => {
        gerandoPdf.value = true;
        const idCodificado = Buffer.from(idExame.toString()).toString('base64');
        const opcoes = {
            headers: getHeaders(),
            params: {
                basename: global.gConfig.basename,
                modulo: global.gConfig.modulo_nome,
                versao: global.gConfig.modulo_versao,
                imagens: true
            }
        }

        axios.get(`${EXAME_API_BASE_URL}/exame/${idCodificado}/imprimir`, opcoes)
            .then((response) => {
                gerandoPdf.value = false;
                window.open(response.data);
            })
            .catch(err => {
                gerandoPdf.value = false;
                console.log(err);
            });
    }

    const imprimirProtocolo = (exame) => {
        gerandoPdf.value = true;
        const idExameCodificado = Buffer.from(exame.id_exame?.toString() || exame.id?.toString()).toString('base64');
        const params = { 
            idExame: idExameCodificado,
            basename: global.gConfig.basename
        };
        axios.get(`${EXAME_API_BASE_URL}/protocolo`, { params, headers: getHeaders() })
            .then((response) => {
                gerandoPdf.value = false;
                window.open(response.data);
            })
            .catch(err => {
                gerandoPdf.value = false;
                const alertConfig = {
                    title: strings.erro,
                    message: strings.erroDesconhecido,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }
                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                };
                alerta.value = alertConfig;
            });
    }

    const imprimirTermo = (exame) => {
        gerandoPdf.value = true;
        let tab = window.open();
        tab.document.write('<p>Aguarde...</p>');
        let url = `${global.gConfig.url_base_espiro}/termo-autorizacao/${btoa(exame.id_paciente)}`;
        axios
            .get(url, { headers: getHeaders(), responseType: 'blob' })
            .then((response) => {
                gerandoPdf.value = false;
                if (response.data) {
                    const fileURL = URL.createObjectURL(response.data);
                    tab.location = fileURL;
                }
            })
            .catch(err => {
                gerandoPdf.value = false;
                console.log(err);
                const alertConfig = {
                    title: strings.erro,
                    message: strings.erroGerarTermo,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }
                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                };
                alerta.value = alertConfig;
            });

    }

    return (
        <>
            <Routes>
                <Route path="/visualizar/:id" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/visualizar" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/" element={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
            </Routes>
            <div className={classes.carregando}>
                <SttLoading
                    open={gerandoPdf.value}
                    text={strings.gerandoDocumento}
                />
            </div>
        </>
    );

}

export default Aba;