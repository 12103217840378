import React, { lazy, Suspense, useContext } from 'react';
import { temPerfilRBAC, temPermissaoRBAC } from '../../secutity/rbac';
import { PERFIL, PERMISSOES } from '../../common/Constants';
import useStyles from './style';
import {
    SttContainer,
    SttCircularProgress,
    SttGrid,
    SttTranslateHook
} from '@stt-componentes/core';
import usuario from '../../signals/usuario';

const Produtividade = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const LaudosEmitidos = lazy(() => import('./laudos-emitidos'));
    const SemLaudo72h = lazy(() => import('./sem-laudo-72h'));
    const SemLaudo = lazy(() => import('./sem-laudo'));
    const ExameMaisAntigoSemLaudo = lazy(() => import('./exame-mais-antigo-sem-laudo'));
    const ExamesComLaudo = lazy(() => import('./exames-com-laudo'));
    const ExamesInvalidos = lazy(() => import('./exames-invalidos'));

    return (
        <SttContainer className={classes.container}>
            <SttGrid container spacing={3}>
                {/* Coluna esquerda */}
                {
                    (temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME) || temPerfilRBAC(usuario, [PERFIL.ADMINISTRADOR, PERFIL.ADMINISTRADOR_ESTADUAL])) &&
                    <SttGrid item xs={12} md={6}>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <LaudosEmitidos strings={strings} />
                        </Suspense>
                    </SttGrid>
                }
                {
                    !temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) && !temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL) && !temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME) &&
                    <SttGrid item xs={12} md={6}>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <ExamesComLaudo strings={strings} />
                        </Suspense>
                    </SttGrid>
                }

                {/* Coluna direita */}
                {
                    !temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) && !temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL) && !temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME) &&
                    <SttGrid item xs={12} md={6}>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <ExamesInvalidos strings={strings} />
                        </Suspense>
                    </SttGrid>
                }
                {
                    (temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME) || temPerfilRBAC(usuario, [PERFIL.ADMINISTRADOR, PERFIL.ADMINISTRADOR_ESTADUAL])) &&
                    <SttGrid item xs={12} md={6}>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <SemLaudo72h strings={strings} />
                        </Suspense>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <SemLaudo strings={strings} />
                        </Suspense>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <ExameMaisAntigoSemLaudo strings={strings} />
                        </Suspense>
                    </SttGrid>
                }
            </SttGrid>
        </SttContainer>
    )
}

export default Produtividade;