export const CAMPOS_PACIENTE = {
    camposBusca: {
        cpf: { obrigatorio: false },
        cns: { obrigatorio: false }
    },
    camposCadastro: [
        [
            { nome: 'nome', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            { nome: 'genero', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
            { nome: 'dataNascimento', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
        ],
        [
            { nome: 'cpf', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'cns', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'cep', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
        ],
        [
            { nome: 'logradouro', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 7, md: 7 } },
            { nome: 'bairro', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 5, md: 5 } },
        ],
        [
            { nome: 'pais', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'uf', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            { nome: 'municipio', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
        ],
        [
            { nome: 'tipoContato1', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            { nome: 'contato1', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
        ],
        [
            { nome: 'profissao', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 8, md: 8 } },
            { nome: 'peso', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            { nome: 'altura', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
        ]
    ]
};