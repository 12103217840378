import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttSeeButton,
    SttHidden,
    SttText,
    SttTranslateHook
} from '@stt-componentes/core';
import Form from './form';
import Detalhes from './detalhes';
import { batch } from '@preact/signals-react';
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        DATA: 'data',
        SOLICITANTE: 'solicitante',
        PACIENTE: 'nome_paciente',
        CIDADE_PROCEDENCIA: 'nome_cidade'
    }
}

const SolicitacoesEmAberto = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const solicitacoes = useSignal([]);
    const buscaEmAndamento = useSignal(false);
    const page = useSignal(global.gConfig.pagination.start);
    const count = useSignal(global.gConfig.pagination.count);
    const totalRegistros = useSignal(0);
    const order = useSignal(ORDENACAO.DIRECAO.ASC);
    const orderBy = useSignal(null);
    const buscar = useSignal(true);
    const idSolicitacaoSelecionada = useSignal(null);

    const resetPageOrder = () => {
        batch(() => {
            page.value = global.gConfig.pagination.start;
            count.value = global.gConfig.pagination.count;
            order.value = ORDENACAO.DIRECAO.DESC;
            orderBy.value = ORDENACAO.CAMPOS.DATA;
        });
    }

    const handleClickSort = (campo) => {
        const isAsc = (orderBy.value === campo) && (order.value === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        batch(() => {
            order.value = newOrder;
            orderBy.value = campo;
            buscar.value = true;
        });
    }

    const handleChangePage = (event, newPage) => {
        batch(() => {
            page.value = newPage
            buscar.value = true;
        });
    };

    const handleChangeRowsPerPage = event => {
        batch(() => {
            count.value = event.target.value;
            page.value = global.gConfig.pagination.start;
            buscar.value = true;
        });
    };

    const callbackBusca = (dados) => {
        batch(() => {
            if (dados) {
                totalRegistros.value = parseInt(dados.totalRegistros);
                solicitacoes.value = dados.itens
            } else {
                totalRegistros.value = 0;
                solicitacoes.value = [];
            }
            buscar.value = false;
        });
    }

    const handleClickVoltar = (atualizarBusca) => {
        batch(() => {
            idSolicitacaoSelecionada.value = null;
            buscar.value = !!atualizarBusca;
        });
    }

    if (idSolicitacaoSelecionada.value) {
        return <Detalhes id={idSolicitacaoSelecionada.value} voltar={handleClickVoltar} />
    }

    return (
        <>
            <Form
                callbackBusca={callbackBusca}
                buscaEmAndamento={buscaEmAndamento}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
                resetPageOrder={resetPageOrder}
            />

            <div className={classes.tableWrapper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="12%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.DATA}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.DATA ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA)}
                                    >
                                        {strings.dataSolicitacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="30%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SOLICITANTE)}
                                    >
                                        {strings.solicitante}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="27%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.PACIENTE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.PACIENTE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.PACIENTE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.PACIENTE)}
                                    >
                                        {strings.paciente}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="25%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA)}
                                    >
                                        {`${strings.municipio}/${strings.uf}`}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="6%" align="center">
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">Solicitações de exames</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento.value
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={5}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    solicitacoes.value.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={5}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        solicitacoes.value.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{`${row.data_formatada} ${global.gConfig.sufixo_data_hora}`}</SttTableCell>
                                                    <SttTableCell>{row.solicitante}</SttTableCell>
                                                    <SttTableCell>{row.nome_paciente}</SttTableCell>
                                                    <SttTableCell>{row.nome_cidade_estado}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.data}:</b> {row.data_formatada}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.solicitante}:</b> {row.solicitante}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.paciente}:</b> {row.nome_paciente}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{`${strings.municipio}/${strings.uf}`}:</b> {row.nome_cidade_estado}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center">
                                                    <SttSeeButton
                                                        id={`btn-ver-solicitacao-${index}`}
                                                        onClick={() => {
                                                            idSolicitacaoSelecionada.value = row.id;
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </div>
            {
                solicitacoes.value.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros.value}
                            rowsPerPage={count.value}
                            page={page.value}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={(params) => `${params.from}-${params.to} ${strings.de} ${params.count}`}
                        />
                    ) : null
            }
        </>
    )

}

export default SolicitacoesEmAberto;