import React, { lazy, Suspense, memo, useContext } from 'react';
import {
    SttTabs,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const Comorbidades = lazy(() => import('../../componentes/administrativo/comorbidades/pesquisa'));

const ComorbidadeSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Comorbidades {...props} />
        </Suspense>
    )
});

const IndexAdministrativo = () => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const abas = useSignal([{
        titulo: strings.comorbidadesTitulo,
        conteudo: ComorbidadeSuspense,
        key: 'ComorbidadeSuspense',
        permanente: true
    }]);

    const abaAtiva = useSignal('0');

    return (
        <SttTabs abas={abas.value}
            className={classes.wrapper}
            handleChangeAbaAtiva={(a) => abaAtiva.value = a}
            abaAtiva={abaAtiva.value}
            permanente={true}
            preload={false}
        />
    );
};

export default IndexAdministrativo;