import React, { useContext } from 'react';
import HttpStatus from 'http-status-codes';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { getHeaders } from '../../request';
import axios from 'axios';
import {
    SttModal,
    SttButton,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../signals/usuario';
import alerta from '../../signals/alerta';

const ConfirmarLaudo = (props) => {
    useSignals();

    const { html, callbackConfirmar, callbackCancelar, open, idExame } = props;
    const { values, resetForm } = useFormikContext();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const confirmando = useSignal(false);
    const assinando = useSignal(false);

    const confirmar = () => {
        confirmando.value = true;
        axios.post(`${global.gConfig.url_base_espiro}/laudo/confirmar`, values, { headers: getHeaders() })
            .then((response) => {
                resetForm();
                if (usuario.value.cpf && usuario.value.idufsc) {
                    const urlAuthAssinaturaDigital = `${global.gConfig.url_base_assinatura_digital_ufsc}?cpfAssinador=${usuario.value.cpf}`;
                    let win = window.open(urlAuthAssinaturaDigital, "_blank", "height=800, width=1000;");
                    let timer = setInterval(() => {
                        if (win.closed) {
                            clearInterval(timer);
                            assinando.value = true;
                            let tipoAlertaSolicitacao = '';
                            let tituloAlertaSolicitacao = '';
                            let mensagemAlertaSolicitacao = '';
                            let options = [{
                                title: strings.ok,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                    callbackConfirmar();
                                }
                            }];
                            let onClose = () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                                callbackConfirmar();
                            };
                            axios.post(`${global.gConfig.url_base_exames}/assinatura-laudo`, { exame: idExame }, { headers: getHeaders() })
                                .then((res) => {
                                    tituloAlertaSolicitacao = strings.sucesso;
                                    mensagemAlertaSolicitacao = strings.laudoAssinado;
                                    tipoAlertaSolicitacao = 'success';
                                })
                                .catch(err => {
                                    tituloAlertaSolicitacao = strings.erro;
                                    mensagemAlertaSolicitacao = strings.erroAssinatura;
                                    tipoAlertaSolicitacao = 'error';
                                })
                                .finally(() => {
                                    assinando.value = false;
                                    alerta.value = {
                                        ...alerta.value,
                                        type: tipoAlertaSolicitacao,
                                        title: tituloAlertaSolicitacao,
                                        message: mensagemAlertaSolicitacao,
                                        open: true,
                                        options: options,
                                        onClose: onClose
                                    }
                                });
                        }
                    }, 1500);
                } else {
                    callbackConfirmar();
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                let titulo;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        titulo = data.message;
                    } else {
                        titulo = strings.erro;
                    }
                } else {
                    titulo = strings.erro;
                }
                alerta.value = {
                    ...alerta.value,
                    type: 'error',
                    title: titulo,
                    message: msg,
                    open: true,
                    options: {
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    },
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }
            })
            .finally(() => {
                confirmando.value = false;
            });
    }

    return (
        <>
            <SttModal
                title={strings.resumoLaudo}
                open={open}
                outModalClose={callbackCancelar}
                iconClose={callbackCancelar}
                maxWidth="lg"
                fullWidth={true}
                children={
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" disabled={confirmando.value} onClick={confirmar}>
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={callbackCancelar}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <SttLoading
                open={confirmando.value}
                text={strings.confirmando}
            />
            <SttLoading
                open={assinando.value}
                text={strings.assinando}
            />
        </>
    );
}

ConfirmarLaudo.propTypes = {
    callbackConfirmar: PropTypes.func.isRequired,
    callbackCancelar: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default ConfirmarLaudo;