export const PERFIL = {
    MEDICO_SOLICITANTE: 'medicoSolicitante',
    MEDICO_LAUDADOR: 'medicoLaudador',
    ADMINISTRADOR: 'administrador',
    TECNICO: 'tecnico',
    REGULADOR_EXAME: 'reguladorExame',
    VISUALIZADOR: 'visualizador',
    VISUALIZADOR_REDE: 'visualizadorRede',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
};

export const PERMISSOES = {
    ADMINISTRATIVO: 'ESPIROMETRIA_ADMINISTRATIVO',
    CRIAR_EXAME: 'ESPIROMETRIA_CRIAR_EXAME',
    ESPIROMETRIA: 'ESPIROMETRIA_ESPIROMETRIA',
    INVALIDAR_EXAME: 'ESPIROMETRIA_INVALIDAR_EXAME',
    INVALIDAR_EXAME_LAUDADO: 'ESPIROMETRIA_INVALIDAR_EXAME_LAUD',
    LAUDAR_EXAME: 'ESPIROMETRIA_LAUDAR_EXAME',
    LAUDAR_EXAME_FABRICA: 'ESPIROMETRIA_LAUDAR_EXAME_FABRICA',
    PRIORIZAR_EXAME: 'ESPIROMETRIA_PRIORIZAR_EXAME',
    SOLICITAR_EXAME: 'ESPIROMETRIA_SOLICITAR_EXAME',
    TROCAR_REDE: 'ESPIROMETRIA_TROCAR_REDE',
    VISUALIZAR_EXAME: 'ESPIROMETRIA_VISUALIZACAO',
    VISUALIZAR_IMAGEM: 'ESPIROMETRIA_VISUALIZAR_IMAGEM',
    VISUALIZAR_LAUDO: 'ESPIROMETRIA_VISUALIZAR_LAUDO',
    VISUALIZAR_SOLICITACAO: 'ESPIROMETRIA_VISUALIZAR_SOLIC_EX'
};

export const MODALIDADE = {
    SIGLA: 'ESP',
    DESCRICAO: 'Spirometry',
    CODIGO: '1.2.826.0.1.3680043.8.213.51',
    DESCRICAO_PT: 'Espirometria'
};

export const ORIGEM_MANUAL = 'M';

export const TABAGISMO = {
    FUMANTE: 1,
    EX_FUMANTE: 2,
    NUNCA_FUMOU: 3
};

export const ETILISMO = {
    NUNCA: 0,
    MENSALMENTE_MENOS: 1,
    DUAS_QUATRO_VEZES_MES: 2,
    DUAS_TRES_VEZES_SEMANA: 3,
    QUATRO_OU_MAIS_VEZES_SEMANA: 4
};

export const MOTIVO = {
    INVESTIGACAO_DIAGNOSTICA: 'D',
    PRE_OPERATORIO: 'P',
    CONTROLE_TRATAMENTO: 'T',
    OCUPACIONAL: 'O',
}

export const CONSELHO_TRABALHO = {
    CRM: 'CRM'
}

export const TIPO_ANEXO = {
    PDF: 'pdf',
    BMP: 'bmp',
    JPG: 'jpg',
    JPEG: 'jpeg',
    PNG: 'png',
}

const descritorLaudo = {
    NORMAL: 'NORMAL',
    DV_OBSTRUTIVO: 'DV_OBSTRUTIVO',
    DV_RESTRITIVO: 'DV_RESTRITIVO',
    DV_MISTO: 'DV_MISTO',
    DVO_CVF_REDUZIDA: 'DVO_CVF_REDUZIDA',
    DVO_HIPERINSUFLACAO: 'DVO_HIPERINSUFLACAO',
    DV_INESPECIFICO: 'DV_INESPECIFICO',
    OUTRO: 'OUTRO'
}

export const DESCRITOR_LAUDO = {
    [descritorLaudo.NORMAL]: {
        value: descritorLaudo.NORMAL,
        label: 'Normal',
        complemento: false,
        especificacao: false
    },
    [descritorLaudo.DV_OBSTRUTIVO]: {
        value: descritorLaudo.DV_OBSTRUTIVO,
        label: 'DV Obstrutivo',
        complemento: false,
        especificacao: true
    },
    [descritorLaudo.DV_RESTRITIVO]: {
        value: descritorLaudo.DV_RESTRITIVO,
        label: 'DV Restritivo',
        complemento: false,
        especificacao: true
    },
    [descritorLaudo.DV_MISTO]: {
        value: descritorLaudo.DV_MISTO,
        label: 'DV Misto',
        complemento: false,
        especificacao: true
    },
    [descritorLaudo.DVO_CVF_REDUZIDA]: {
        value: descritorLaudo.DVO_CVF_REDUZIDA,
        label: 'DVO com CVF Reduzida',
        complemento: false,
        especificacao: true
    },
    [descritorLaudo.DVO_HIPERINSUFLACAO]: {
        value: descritorLaudo.DVO_HIPERINSUFLACAO,
        label: 'DVO com Hiperinsuflação',
        complemento: false,
        especificacao: false
    },
    [descritorLaudo.DV_INESPECIFICO]: {
        value: descritorLaudo.DV_INESPECIFICO,
        label: 'DV Inespecífico',
        complemento: false,
        especificacao: false
    },
    [descritorLaudo.OUTRO]: {
        value: descritorLaudo.OUTRO,
        label: 'Outro',
        complemento: true,
        especificacao: false
    },
}

const especificacao = {
    LEVE: 'LEVE',
    MODERADO: 'MODERADO',
    ACENTUADO: 'ACENTUADO'
}

export const ESPECIFICACAO_LAUDO = {
    [especificacao.LEVE]: {
        value: especificacao.LEVE,
        label: 'Leve',
    },
    [especificacao.MODERADO]: {
        value: especificacao.MODERADO,
        label: 'Moderado',
    },
    [especificacao.ACENTUADO]: {
        value: especificacao.ACENTUADO,
        label: 'Acentuado',
    },
}

const respostaBronco = {
    POSITIVA: 'POSITIVA',
    NEGATIVA: 'NEGATIVA',
    APENAS_CVF: 'APENAS_CVF'
}

export const RESPOSTA_BRONCO = {
    [respostaBronco.POSITIVA]: {
        value: respostaBronco.POSITIVA,
        label: 'Significativa',
    },
    [respostaBronco.NEGATIVA]: {
        value: respostaBronco.NEGATIVA,
        label: 'Não-significativa',
    },
    [respostaBronco.APENAS_CVF]: {
        value: respostaBronco.APENAS_CVF,
        label: 'Não realizada',
    },
}