import React, { useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders } from '../../../request';
import { TABAGISMO, PERMISSOES } from '../../../common/Constants';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import EnvioImagens from '../index';
import { temPermissaoRBAC } from '../../../secutity/rbac';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../../signals/usuario';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    conteinerLesao: {
        marginBottom: theme.spacing(1)
    }
}));

const Detalhes = ({ id, voltar }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const idComponenteEnvioImagens = useSignal(Math.random());
    const solicitacao = useSignal(null);
    const dadosEnvioImagens = useSignal([]);
    const mostrarModalEnvioImagens = useSignal(false);
    const comorbidades = useSignal([]);

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_espiro}/solicitacao/${id}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { data } = response.data;
                    solicitacao.value = data;

                    dadosEnvioImagens.value = {
                        id: data.id_solicitacao,
                        instituicao: data.id_instituicao
                    };

                    comorbidades.value = data.comorbidades.map(c => c.descricao);
                }
            })
            .catch(err => console.log(err));
    }, []);

    const handleAbrirModalEnvioImagens = () => {
        idComponenteEnvioImagens.value = Math.random();
        mostrarModalEnvioImagens.value = true;
    }

    const callbackEnvioImagens = () => {
        voltar(true);
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                !solicitacao.value
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center">{strings.solicitacao}</SttHeading>

                        <SttExpansionPanel
                            title={strings.dadosGerais}
                            children={
                                <div>
                                    <SttTextItem key="1" title={strings.modalidade} content={solicitacao.value.modalidade} />
                                    <SttTextItem key="2" title={strings.dataSolicitacao} content={`${solicitacao.value.data_solicitacao_formatada} ${global.gConfig.sufixo_data_hora}`} />
                                    <SttTextItem key="3" title={strings.solicitante} content={solicitacao.value.nome_solicitante} />
                                    <SttTextItem key="4" title={strings.cidadeUf} content={`${solicitacao.value.cidade} / ${solicitacao.value.uf}`} />
                                </div>
                            }
                        />

                        <SttExpansionPanel
                            title={strings.dadosPaciente}
                            children={
                                <div>
                                    <SttTextItem key="5" title={strings.nome} content={solicitacao.value.nome_paciente} />
                                    <SttTextItem key="6" title={strings.dataNascimento} content={solicitacao.value.data_nascimento_paciente} />
                                    <SttTextItem key="7" title={strings.idade} content={`${solicitacao.value.idade_paciente} anos`} />
                                    <SttTextItem key="8" title={strings.genero} content={solicitacao.value.sexo_paciente} />
                                    <SttTextItem key="9" title={strings.cns} content={solicitacao.value.cartao_sus} />
                                    <SttTextItem key="10" title={strings.peso} content={solicitacao.value.peso_paciente} />
                                    <SttTextItem key="11" title={strings.altura} content={solicitacao.value.altura_paciente} />
                                    <SttTextItem key="12" title={strings.ocupacao} content={solicitacao.value.profissao_paciente} />
                                </div>
                            }
                        />

                        <SttExpansionPanel
                            title={strings.indicacaoClinica}
                            children={
                                <div>
                                    <SttTextItem key="13" title={strings.tabagismo} content={solicitacao.value.descricacao_tabagismo} />
                                    {
                                        (solicitacao.value.tabagismo === TABAGISMO.FUMANTE || solicitacao.value.tabagismo === TABAGISMO.EX_FUMANTE) &&
                                        <>
                                            <SttTextItem key="13.1" title={strings.cigarrosDia} content={solicitacao.value.cigarros_dia} />
                                            <SttTextItem key="13.2" title={strings.anosFuma} content={solicitacao.value.anos_tabagismo} />
                                        </>
                                    }
                                    {
                                        solicitacao.value.tabagismo === TABAGISMO.EX_FUMANTE &&
                                        <>
                                            <SttTextItem key="13.3" title={strings.anosParouFumo} content={solicitacao.value.anos_parou_fumar} />
                                        </>
                                    }
                                    <SttTextItem key="14" title={strings.etilismo} content={solicitacao.value.etilismo} />

                                    {comorbidades.length > 0 && <SttTextItem key="20" title={strings.comorbidades} content={comorbidades.join(', ')} />}
                                    {
                                        solicitacao.value.motivo_exame &&
                                        <SttTextItem key="15" title={strings.motivoExame} content={solicitacao.value.motivo_exame} />
                                    }
                                    {
                                        solicitacao.value.resumo_historia_clinica &&
                                        <SttTextItem key="16" title={strings.observacoes} content={solicitacao.value.resumo_historia_clinica} />
                                    }
                                    {
                                        solicitacao.value.hmf &&
                                        <SttTextItem key="17" title={strings.hmf} content={solicitacao.value.hmf} />
                                    }
                                    {
                                        solicitacao.value.hmp &&
                                        <SttTextItem key="18" title={strings.hmp} content={solicitacao.value.hmp} />
                                    }
                                    {
                                        solicitacao.value.medicamentos_em_uso &&
                                        <SttTextItem key="19" title={strings.medicamentosUso} content={solicitacao.value.medicamentos_em_uso} />
                                    }
                                </div>
                            }
                        />
                        {
                            temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME) &&
                            <SttButton
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleAbrirModalEnvioImagens}
                                nomarginleft="true"
                            >
                                {strings.enviarImagens}
                            </SttButton>
                        }
                    </>
            }

            {
                temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME) &&
                <EnvioImagens
                    open={mostrarModalEnvioImagens.value}
                    resetFormulario={() => {
                        mostrarModalEnvioImagens.value = false;
                    }}
                    solicitacao={dadosEnvioImagens.value}
                    callbackFinalizarEnvio={callbackEnvioImagens}
                    key={idComponenteEnvioImagens.value}
                />
            }
        </SttContainer>
    )

}

export default Detalhes;