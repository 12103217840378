import React, { useContext } from 'react';
import DadosGerais from '../informacoes-gerais/dadosGerais';
import { FIELDS } from './fieldNames';
import { makeStyles } from "@material-ui/core/styles";
import Anexos from '../anexos';
import {
    SttModal,
    SttButton,
    SttStepper,
    SttMobileStepper,
    SttHidden,
    SttTranslateHook
} from '@stt-componentes/core';
import { useFormikContext } from 'formik';
import { useSignalEffect } from '@preact/signals-react';
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    mobileStepper: {
        marginBottom: theme.spacing(1)
    }
}));

const InformacoesGerais = (props) => {
    const {
        confirmarFecharModal,
        open,
        callbackFinalizado,
        equipamentos,
        steps,
        stepAtual
    } = props;
    useSignals();

    const classes = useStyles();

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { setFieldValue, values, touched, setTouched, validateForm } = useFormikContext();

    const selecionarAnexos = useSignal(false);

    useSignalEffect(() => {
        if (equipamentos.value.length === 1) {
            setFieldValue(FIELDS.EQUIPAMENTO, equipamentos.value[0]);
        }
    });

    const proximoStep = () => {
        stepAtual.value = stepAtual.value + 1;
        selecionarAnexos.value = true;
    }

    const stepAnterior = () => {
        stepAtual.value = stepAtual.value - 1;
        selecionarAnexos.value = false;
        setFieldValue('anexo', [{}]);
    }

    const finalizarConjuntoSteps = (val) => {
        callbackFinalizado(val);
    }

    return (
        <SttModal
            title={strings.envioImagens}
            open={open}
            outModalClose={confirmarFecharModal}
            iconClose={confirmarFecharModal}
            maxWidth="lg"
            fullWidth={true}
            children={
                <>
                    <SttHidden only={['xs', 'sm']}>
                        <SttStepper steps={steps.map((step) => step.titulo)} activeStep={stepAtual.value} />
                        {
                            !selecionarAnexos.value ?
                                <DadosGerais equipamentos={equipamentos} /> :
                                <Anexos />
                        }

                    </SttHidden>
                    <SttHidden mdUp>
                        {
                            !selecionarAnexos.value ?
                                <DadosGerais equipamentos={equipamentos} /> :
                                <Anexos />
                        }
                        <SttMobileStepper
                            className={classes.mobileStepper}
                            steps={steps.length}
                            activeStep={stepAtual.value}
                        />
                    </SttHidden>
                </>
            }
            footer={
                <>
                    {
                        stepAtual.value > 0 &&
                        <SttButton
                            variant="outlined"
                            color="primary"
                            onClick={stepAnterior}>
                            {strings.anterior}
                        </SttButton>
                    }
                    {
                        stepAtual.value < steps.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={() => validateForm().then((erros) => {
                                if (Object.keys(erros).length) {
                                    setTouched({ ...touched, ...erros });
                                    return;
                                }
                                proximoStep();
                            })}>
                            {strings.proximo}
                        </SttButton>
                    }
                    {
                        stepAtual.value === steps.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={finalizarConjuntoSteps}>
                            {strings.enviarImagens}
                        </SttButton>
                    }
                    <SttButton
                        variant="outlined"
                        color="secondary"
                        onClick={confirmarFecharModal}
                    >
                        {strings.cancelar}
                    </SttButton>
                </>
            }
        />
    );
}

export default InformacoesGerais;