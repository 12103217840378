import React, { useRef, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import {
    SttGrid,
    SttButton,
    SttTranslateHook,
    SttLoading,
    SttExpansionPanel,
    SttDivider
} from '@stt-componentes/core';
import { values } from './initialValues';
import { useLocation } from 'react-router-dom';
import LaudoTextual from './abaTextual';
import SttCid10 from '@stt-componentes/cid10';
import SttDecs from '@stt-componentes/decs';
import ModalConfirmacao from './modalConfirmacao';
import laudar from '../../signals/laudo';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { listar } from '../../signals/exame';
import alerta from '../../signals/alerta';
import usuario from '../../signals/usuario';

const useStyles = makeStyles(theme => ({
    conteinerDecs: {
        paddingTop: theme.spacing(2)
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    }
}));

const Form = (props) => {
    const {
        idExame,
        idLaudoEmissao,
        idPaciente,
        idSolicitante,
        nomeSolicitante,
        callbackProximoExame
    } = props;

    useSignals();
    const location = useLocation();
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const schema = validationSchema(strings);
    const laudoTextualRef = useRef();

    // Controles do modal de confirmação
    const modalAberto = useSignal(false);
    const gerandoResumo = useSignal(false);
    const laudo = useSignal(null);
    const assinando = useSignal(false);
    const contextoFabricalaudo = useSignal(location.pathname === '/laudo');
    
    const callbackCancelar = () => {
        modalAberto.value = false;
    }
    
    const callbackConfirmar = () => {
        modalAberto.value = false;
        if (contextoFabricalaudo.value) {
            callbackProximoExame();
        } else {
            laudar.value = false;
            listar.value = true;
        }
    }

    const gerarResumo = (data) => {
        gerandoResumo.value = true;
        axios.post(`${global.gConfig.url_base_espiro}/laudo/resumo`, data, { headers: getHeaders() })
            .then((response) => {
                laudo.value = response.data.data;
                modalAberto.value = true;
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                let titulo;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        response.data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        titulo = response.data.message;
                    } else {
                        titulo = strings.erro;
                    }
                } else {
                    titulo = strings.erro;
                }
                const alertConfig = {
                    title: titulo,
                    message: msg,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }
                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                };
                alerta.value = alertConfig;
            })
            .finally(() => {
                gerandoResumo.value = false;
            });
    }

    return (
        <Formik
            initialValues={values(idExame, idLaudoEmissao, idPaciente, idSolicitante, nomeSolicitante)}
            validationSchema={schema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
                axios.post(`${global.gConfig.url_base_espiro}/laudo/confirmar`, data, { headers: getHeaders() })
                    .then((response) => {
                        resetForm();
                        if (usuario.value.cpf && usuario.value.idufsc) {
                            const urlAuthAssinaturaDigital = `${global.gConfig.url_base_assinatura_digital_ufsc}?cpfAssinador=${usuario.value.cpf}`;
                            let win = window.open(urlAuthAssinaturaDigital, "_blank", "height=800, width=1000;");
                            let timer = setInterval(() => {
                                if (win.closed) {
                                    clearInterval(timer);
                                    assinando.value = true;
                                    let tipoAlertaSolicitacao = '';
                                    let tituloAlertaSolicitacao = '';
                                    let mensagemAlertaSolicitacao = '';
                                    let options = [{
                                        title: strings.ok,
                                        onClick: () => {
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            }
                                            callbackConfirmar();
                                        }
                                    }];
                                    let onClose = () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        }
                                        callbackConfirmar();
                                    };
                                    axios.post(`${global.gConfig.url_base_exames}/assinatura-laudo`, { exame: idExame }, { headers: getHeaders() })
                                        .then((res) => {
                                            tituloAlertaSolicitacao = strings.sucesso;
                                            mensagemAlertaSolicitacao = strings.laudoAssinado;
                                            tipoAlertaSolicitacao = 'success';
                                        })
                                        .catch(err => {
                                            tituloAlertaSolicitacao = strings.erro;
                                            mensagemAlertaSolicitacao = strings.erroAssinatura;
                                            tipoAlertaSolicitacao = 'error';
                                        })
                                        .finally(() => {
                                            assinando.value = false;
                                            alerta.value = {
                                                ...alerta.value,
                                                type: tipoAlertaSolicitacao,
                                                title: tituloAlertaSolicitacao,
                                                message: mensagemAlertaSolicitacao,
                                                open: true,
                                                options: options,
                                                onClose: onClose
                                            }
                                        });
                                }
                            }, 1500);
                        } else {
                            callbackConfirmar();
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        const { response } = err;
                        let msg = strings.mensagemErroGeral;
                        let titulo;
                        if (response) {
                            if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                                const { data } = response;
                                let arrMensagem = [];
                                data.errors.forEach(error => {
                                    arrMensagem.push(`- ${error.message}`);
                                });
                                msg = arrMensagem.join('\n');
                                titulo = data.message;
                            } else {
                                titulo = strings.erro;
                            }
                        } else {
                            titulo = strings.erro;
                        }
                        alerta.value = {
                            ...alerta.value,
                            type: 'error',
                            title: titulo,
                            message: msg,
                            open: true,
                            options: [{
                                title: strings.ok,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                }
                            }],
                            onClose: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    values,
                    errors,
                    isSubmitting,
                    submitCount,
                    setFieldValue,
                    handleSubmit
                }) => {
                    return (
                        <>
                            <form onSubmit={handleSubmit} noValidate>
                                <SttExpansionPanel
                                    title={strings.laudoTextual}
                                    compact
                                    children={
                                        <>
                                            <div ref={laudoTextualRef}></div>
                                            <LaudoTextual />
                                        </>
                                    }
                                />

                                <SttDivider className={classes.divider} />

                                <SttExpansionPanel
                                    title={strings.descritores}
                                    compact
                                    opened={false}
                                    children={
                                        <SttGrid container spacing={1}>
                                            <SttGrid item xs={12}>
                                                <div className={classes.conteinerDecs}>
                                                    <SttCid10
                                                        strings={strings}
                                                        headers={getHeaders()}
                                                        multiplos
                                                        formExterno={{
                                                            cid10: values.cid10,
                                                            isSubmitting,
                                                            errors,
                                                            submitCount,
                                                            setFieldValue
                                                        }}
                                                    />
                                                </div>
                                            </SttGrid>
                                            <SttGrid item xs={12}>
                                                <div className={classes.conteinerDecs}>
                                                    <SttDecs
                                                        strings={strings}
                                                        multiplos
                                                        formExterno={{
                                                            decs: values.decs,
                                                            isSubmitting,
                                                            errors,
                                                            submitCount,
                                                            setFieldValue
                                                        }}
                                                    />
                                                </div>
                                            </SttGrid>
                                        </SttGrid>
                                    }
                                />

                                <SttDivider className={classes.divider} />

                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}
                                            nomarginleft="true"
                                            onClick={() => {
                                                // setSubmitting(false);
                                                const erros = Object.keys(errors).sort();
                                                if (erros.length > 0) {
                                                    setTimeout(() => {
                                                        laudoTextualRef.current.scrollIntoView({
                                                            behavior: 'smooth',
                                                            block: 'center',
                                                            inline: 'start'
                                                        });
                                                    }, 300);
                                                }

                                            }}
                                        >
                                            {strings.publicar}
                                        </SttButton>
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                const erros = Object.keys(errors).sort();
                                                if (erros.length > 0) {
                                                    setTimeout(() => {
                                                        laudoTextualRef.current.scrollIntoView({
                                                            behavior: 'smooth',
                                                            block: 'center',
                                                            inline: 'start'
                                                        });
                                                    }, 300);
                                                    return;
                                                }
                                                gerarResumo(values);
                                            }}
                                        >
                                            {strings.visualizarResumo}
                                        </SttButton>
                                    </SttGrid>
                                </SttGrid>
                            </form>
                            <SttLoading
                                open={isSubmitting}
                                text={strings.confirmando}
                            />
                            <SttLoading
                                open={gerandoResumo.value}
                                text={strings.gerandoResumo}
                            />
                            <SttLoading
                                open={assinando.value}
                                text={strings.assinando}
                            />
                            <ModalConfirmacao
                                html={laudo.value}
                                open={modalAberto.value}
                                idExame={idExame}
                                callbackConfirmar={callbackConfirmar}
                                callbackCancelar={callbackCancelar}
                            />
                        </>
                    )
                }
            }
        </Formik>
    );
};

export default Form;