import React, { useRef, memo, lazy, Suspense, useContext } from 'react';
import { Formik } from 'formik';
import { PERMISSOES } from '../../common/Constants';
import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import axios from 'axios';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import EnvioImagens from '../../componentes/envio-imagens'
import { temPermissaoRBAC } from '../../secutity/rbac';
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttButton,
    SttLoading,
    SttFormHelperText,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { CAMPOS_PACIENTE } from './camposPacientes';
import { initialValues } from './initialValues';
import { makeStyles } from '@material-ui/core/styles';
import alerta from '../../signals/alerta';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { batch } from '@preact/signals-react';
import usuario from '../../signals/usuario';

const Paciente = lazy(() => import('@stt-componentes/paciente'));
const Solicitante = lazy(() => import('../../componentes/solicitacao/solicitante'));
const InformacoesClinicas = lazy(() => import('../../componentes/solicitacao/informacoes-clinicas'));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    buttonWrapper: {
        marginTop: theme.spacing(2)
    },
}));

const campos = CAMPOS_PACIENTE;

const Solicitacao = () => {
    useSignals();
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const schema = validationSchema(strings, campos.camposCadastro);

    const handleAbrirModalEnvioImagens = () => {
        alerta.value = {
            ...alerta.value,
            open: false
        };
        enviarImagens.value = temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME);
    }

    const enviarImagens = useSignal(false);
    const gerarTermo = useSignal(false);
    const gerandoTermo = useSignal(false);
    const solicitacao = useSignal(null);
    const instituicaoSolicitante = useSignal(null);
    const imc = useSignal(null);
    const paciente = useSignal(null);
    const secoesAbertas = useSignal({
        'paciente': true,
        'solicitante': false,
        'informacoesClinicas': false,
    });

    const secaoPaciente = useRef(null);
    const secaoSolicitante = useRef(null);
    const secaoInfoClicnicas = useRef(null);

    const handleFinalizarEnvioImagens = () => {
        solicitacao.value = null;
        instituicaoSolicitante.value = null;
        paciente.value = null;
    }

    const imprimirTermo = () => {
        let tab = window.open();
        tab.document.write('<p>Aguarde...</p>');
        const idBuffer = Buffer.from(paciente.value.toString());
        gerandoTermo.value = true;
        axios.get(`${global.gConfig.url_base_espiro}/termo-autorizacao/${idBuffer.toString('base64')}/uf/${instituicaoSolicitante.value.uf}`, { headers: getHeaders(), responseType: 'blob' })
            .then((response) => {
                if (response.data) {
                    const fileURL = URL.createObjectURL(response.data);
                    tab.location = fileURL;
                }
                if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
                    abrirModalEnvioExame()
                }
            })
            .catch(err => {
                console.log(err);
                alerta.value = {
                    ...alerta.value,
                    type: strings.erro,
                    message: strings.erroGerarTermo,
                    type: 'error',
                    options: [{
                        title: strings.ok,
                        onClick: () => {
                            if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
                                abrirModalEnvioExame()
                            } else {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }
                    }],
                    onClose: () => {
                        if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
                            abrirModalEnvioExame()
                        } else {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    },
                    open: true
                }
            })
            .finally(() => {
                batch(() => {
                    gerandoTermo.value = false;
                    gerarTermo.value = false;
                    paciente.value = null;
                    instituicaoSolicitante.value = null;
                });
            });
    }

    useSignalEffect(() => {
        if (gerarTermo.value) {
            imprimirTermo();
        } else {
            batch(() => {
                paciente.value = null;
                instituicaoSolicitante.value = false;
            });
        }
    })

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas.value
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }
        novoSecoesAbertas[secao] = estado;
        secoesAbertas.value = novoSecoesAbertas;
    }

    const verificarSecoesComErro = (validateForm) => {
        validateForm().then((retorno) => {
            let node = null;

            if (retorno['paciente']) {
                abrirSecao('paciente', true);
                node = secaoPaciente.current;
            } else if (retorno['solicitante']) {
                abrirSecao('solicitante', true);
                node = secaoSolicitante.current;
            } else if (retorno['informacoesClinicas']) {
                abrirSecao('informacoesClinicas', true);
                node = secaoInfoClicnicas.current;
            }

            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 200);
            }
        });
    }

    const abrirModalTermo = () => {
        const alertConfig = {
            title: strings.termoEsclarecimento,
            message: strings.mensagemConfirmarTermo_solicitacao,
            type: 'confirmation',
            open: true,
            options: [
                {
                    title: strings.sim,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                        gerarTermo.value = true;
                    }
                },

                {
                    title: strings.nao,
                    onClick: () => {
                        if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
                            abrirModalEnvioExame()
                        }
                    }
                }
            ],
            onClose: () => {
                if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
                    abrirModalEnvioExame()
                }
            }
        };
        alerta.value = alertConfig;
    }

    const abrirModalEnvioExame = () => {
        const alertConfig = {
            title: strings.tituloConfirmarEnvioExame,
            message: strings.mensagemConfirmarEnvioExame,
            type: 'confirmation',
            open: true,
            options: [
                {
                    title: strings.sim,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                        enviarImagens.value = true;
                        handleAbrirModalEnvioImagens();
                    }
                },

                {
                    title: strings.nao,
                    onClick: () => {
                        solicitacao.value = null;
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                }
            ],
            onClose: () => {
                solicitacao.value = null;
                alerta.value = {
                    ...alerta.value,
                    open: false
                };
            }
        };
        alerta.value = alertConfig;
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    let dados = { ...data };
                    dados.digitador = {
                        id: usuario.value.idFuncionario
                    }
                    if (dados.paciente) {
                        if (dados.paciente.cpf) {
                            dados.paciente.cpf = dados.paciente.cpf.replace(/\D+/g, '');
                        }
                        if (dados.paciente.cep) {
                            dados.paciente.cep = dados.paciente.cep.replace(/\D+/g, '');
                        }
                    }
                    let tipoAlertaSolicitacao = '';
                    let tituloAlertaSolicitacao = '';
                    let mensagemAlertaSolicitacao = '';
                    let options = [];
                    let onClose = () => { };

                    axios.post(`${global.gConfig.url_base_espiro}/solicitacao`, dados, { headers: getHeaders() })
                        .then((response) => {
                            const dadosSolicitacao = response.data.data;
                            const dadosPaciente = dadosSolicitacao.paciente;

                            imc.value = null;
                            solicitacao.value = { id: dadosSolicitacao.idSolicitacao, ...dadosSolicitacao };
                            paciente.value = dadosPaciente;
                            instituicaoSolicitante.value = dados.solicitante.instituicao;
                            abrirSecao('paciente', true);

                            tipoAlertaSolicitacao = 'success';

                            options = [{
                                title: strings.ok,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                    abrirModalTermo();
                                }
                            }];

                            onClose = () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                };
                                abrirModalTermo();
                            }

                            tituloAlertaSolicitacao = strings.sucesso;
                            mensagemAlertaSolicitacao = response.data.message;
                            resetForm();
                        })
                        .catch(err => {
                            const { response } = err;
                            let msg = strings.mensagemErroGeral;
                            if (response) {
                                if (response.status === HttpStatus.BAD_REQUEST) {
                                    const dadosResposta = response.data;
                                    let arrMensagem = [];
                                    dadosResposta.errors.forEach(error => {
                                        arrMensagem.push(`- ${error.message}`);
                                    });
                                    msg = arrMensagem.join('\n');
                                    tipoAlertaSolicitacao = 'error';
                                    tituloAlertaSolicitacao = dadosResposta.message;
                                    mensagemAlertaSolicitacao = msg;
                                } else {
                                    tipoAlertaSolicitacao = 'error';
                                    tituloAlertaSolicitacao = strings.erro;
                                    mensagemAlertaSolicitacao = msg;
                                }
                            } else {
                                tipoAlertaSolicitacao = 'error';
                                tituloAlertaSolicitacao = strings.erro;
                                mensagemAlertaSolicitacao = msg;
                            }
                            options = [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        }
                                    }
                                }
                            ];

                            onClose = () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                };
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                            alerta.value = {
                                ...alerta.value,
                                type: tipoAlertaSolicitacao,
                                title: tituloAlertaSolicitacao,
                                message: mensagemAlertaSolicitacao,
                                open: true,
                                options: options,
                                onClose: onClose
                            }
                        });
                }}
            >
                {
                    ({
                        values,
                        isSubmitting,
                        handleSubmit,
                        errors,
                        touched,
                        submitCount,
                        validateForm,
                        setFieldValue
                    }) => {
                        return (
                            <SttContainer>
                                <form onSubmit={handleSubmit} noValidate>
                                    {/* Dados do paciente */}
                                    <SttExpansionPanel
                                        title={strings.dadosPaciente}
                                        opened={secoesAbertas.value['paciente']}
                                        classegriditem={classes.fullWidth}
                                        callback={estadoInterno => abrirSecao('paciente', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoPaciente}></div>
                                                <Paciente
                                                    strings={strings}
                                                    headers={getHeaders()}
                                                    usarTipoContato
                                                    persistirParametrosBusca
                                                    campos={campos}
                                                    setImcExterno={(imcExterno) => imc.value = imcExterno}
                                                    imc={true}
                                                    formExterno={{
                                                        paciente: values[PACIENTE],
                                                        setFieldValue,
                                                        errors: errors[PACIENTE],
                                                        submitCount
                                                    }}
                                                />

                                                {
                                                    touched.paciente && errors.paciente && errors.paciente.nome &&
                                                    <SttFormHelperText error>
                                                        {strings.pacienteObrigatorio}
                                                    </SttFormHelperText>
                                                }
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    {/* Solicitante */}
                                    <SttExpansionPanel
                                        title={strings.solicitante}
                                        opened={secoesAbertas.value['solicitante']}
                                        classegriditem={classes.fullWidth}
                                        callback={estadoInterno => abrirSecao('solicitante', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoSolicitante}></div>
                                                <Solicitante />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    {/* Informações clínicas */}
                                    <SttExpansionPanel
                                        title={strings.infoClinicas}
                                        opened={secoesAbertas.value['informacoesClinicas']}
                                        callback={estadoInterno => abrirSecao('informacoesClinicas', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoInfoClicnicas}></div>
                                                <InformacoesClinicas imc={imc} />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    <div className={classes.buttonWrapper}>
                                        <SttButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}
                                            nomarginleft="true"
                                            onClick={() => verificarSecoesComErro(validateForm)}
                                        >
                                            {strings.enviar}
                                        </SttButton>
                                    </div>
                                </form>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoSolicitacao}
                                />
                                <SttLoading
                                    open={gerandoTermo.value}
                                    text={strings.gerandoDocumento}
                                />
                            </SttContainer>
                        )
                    }
                }
            </Formik>
            {
                temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME) && solicitacao.value && enviarImagens.value &&
                <EnvioImagens
                    open={enviarImagens.value}
                    resetFormulario={() => {
                        enviarImagens.value = false;
                        solicitacao.value = null;
                    }}
                    solicitacao={solicitacao.value}
                    callbackFinalizarEnvio={handleFinalizarEnvioImagens}
                />
            }
        </>
    );
};

export default Solicitacao;