import * as yup from 'yup'
import { FIELDS } from './fieldNames';
import { TABAGISMO } from '../../../common/Constants';

export default (strings) => {
    return yup.object().shape({
        [FIELDS.INFORMACOES_CLINICAS]: yup.object().shape({
            [FIELDS.TABAGISMO]: yup
                .number()
                .nullable()
                .required(strings.campoObrigatorio),
            [FIELDS.TABAGISMO_CIGARROS_DIA]: yup
                .number()
                .nullable()
                .when(FIELDS.TABAGISMO, {
                    is: (val) => val === TABAGISMO.FUMANTE || val === TABAGISMO.EX_FUMANTE,
                    then: yup
                        .number()
                        .typeError(strings.campoInvalido)
                        .min(1)
                        .required(strings.campoObrigatorio)
                }),
            [FIELDS.TABAGISMO_ANOS_FUMO]: yup
                .number()
                .nullable()
                .when(FIELDS.TABAGISMO, {
                    is: (val) => val === TABAGISMO.FUMANTE || val === TABAGISMO.EX_FUMANTE,
                    then: yup
                        .number()
                        .typeError(strings.campoInvalido)
                        .min(1)
                        .max(99)
                        .required(strings.campoObrigatorio)
                }),
            [FIELDS.TABAGISMO_PAROU_FUMO]: yup
                .number()
                .nullable()
                .when(FIELDS.TABAGISMO, {
                    is: (val) => val === TABAGISMO.EX_FUMANTE,
                    then: yup
                        .number()
                        .typeError(strings.campoInvalido)
                        .min(1)
                        .max(99)
                        .required(strings.campoObrigatorio)
                }),
            [FIELDS.ETILISMO]: yup
                .number()
                .nullable()
                .required(strings.campoObrigatorio),
            [FIELDS.MOTIVO_EXAME]: yup
                .string()
                .trim()
                .nullable()
                .required(strings.campoObrigatorio),
            [FIELDS.RESUMO_HISTORIA]: yup
                .string()
                .trim()
                .nullable()
                .required(strings.campoObrigatorio),
            [FIELDS.HMF]: yup
                .string()
                .trim()
                .nullable(),
            [FIELDS.HMP]: yup
                .string()
                .trim()
                .nullable(),
            [FIELDS.MEDICAMENTOS_USO]: yup
                .string()
                .trim()
                .nullable(),
            [FIELDS.COMORBIDADES]: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    descricao: yup.string(),
                    complemento: yup.boolean()
                }).required(strings.campoObrigatorio)
            )
                .min(1, strings.comorbidadeObrigatorio)
                .required(strings.campoObrigatorio)
        }).required()
    });
};