export const initialValues = {
    paciente: {
        id: '',
        cpf: '',
        cns: '',
        nome: '',
        genero: null,
        dataNascimento: null,
        municipio: null,
        uf: null,
        pais: null,
        peso: '',
        altura: '',
        profissao: '',
        cep: '',
        logradouro: '',
        bairro: '',
        tipoContato1: null,
        contato1: ''
    },
    solicitante: {
        medico: null,
        outroMedico: '',
        instituicao: null
    },
    informacoesClinicas: {
        motivoExame: '',
        tabagismo: '',
        tabagismoCigarrosDia: '',
        tabagismoAnosFumo: '',
        tabagismoParouFumo: '',
        etilismo: '',
        resumoHistoria: '',
        hmf: '',
        hmp: '',
        comorbidades: [],
        comorbidadesOutras: '',
        medicamentosUso: ''
    }
};