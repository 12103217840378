import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { getHeaders } from '../../request';
import Imagens from '../anexos/imagens';
import { SITUACAO_LAUDO } from './constantes';
import { temPermissaoRBAC, temPermissaoRede } from '../../secutity/rbac';
import { PERMISSOES, MODALIDADE, TABAGISMO } from '../../common/Constants';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttGrid,
    SttNotification,
    SttAlertTitle,
    SttTranslateHook
} from '@stt-componentes/core';
import Priorizar from './priorizar';
import Invalidar from './invalidar'
import TrocarRede from './trocarRede';
import { SttIMC } from '@stt-componentes/paciente'
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../signals/usuario';
import alerta from '../../signals/alerta';
import { listar } from '../../signals/exame';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    notificationContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    notification: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
}));

const Visualizacao = ({ imprimirFn, imprimirProtocoloFn, imprimirTermoFn }) => {
    useSignals();

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();

    const idExame = useSignal(null);
    const exame = useSignal(null);
    const priorizar = useSignal(false);
    const invalidar = useSignal(false);
    const trocarRede = useSignal(false);
    const redes = useSignal([]);
    const comorbidades = useSignal([]);
    const permissaoBtns = useSignal([]);

    useSignalEffect(() => {
        if (usuario.value.perfisRBAC && exame.value) {
            let rede;
            usuario.value.perfisRBAC.forEach(p => {
                p.redes && p.redes.forEach(r => {
                    if (r.id === exame.value.exame.id_rede_telemedicina) {
                        rede = r;
                    }
                });
            });
            if (rede) {
                permissaoBtns.value = [
                    temPermissaoRede(rede[0], PERMISSOES.INVALIDAR_EXAME),
                    temPermissaoRede(rede[0], PERMISSOES.PRIORIZAR_EXAME)
                ];
            }
        }
    });

    useEffect(() => {
        if (id) {
            navigate('/exames', { replace: true });
            navigate('/exames/visualizar', { state: { exame: Buffer.from(id, 'base64').toString('utf-8') } });
        }
        axios.get(`${global.gConfig.url_base_utilitarios}/rede?modalidade=${MODALIDADE.SIGLA}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    redes.value = itens;
                }
            })
            .catch(err => console.log(err));
    }, []);

    const voltar = () => {
        navigate(-1);
        listar.value = true;
    }

    useEffect(() => {
        if (location.state?.exame) {
            idExame.value = location.state.exame;
        }
    }, [location]);

    useSignalEffect(() => {
        if (idExame.value && !exame.value) {
            axios.get(`${global.gConfig.url_base_espiro}/exame/${idExame}`, { headers: getHeaders() })
                .then((response) => {
                    const { data } = response;
                    exame.value = data;

                    if (data.indicacao?.comorbidades) {
                        comorbidades.value = data.indicacao.comorbidades.map(c => c.descricao);
                    }
                })
                .catch(err => {
                    const { response } = err;
                    let msg = strings.erroDesconhecido;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }

                    const alertConfig = {
                        title: strings.erro,
                        message: msg,
                        type: 'error',
                        open: true,
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                    voltar();
                                }
                            }
                        ],
                        onClose: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                            voltar();
                        }
                    };
                    alerta.value = alertConfig;
                });
        }
    });

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                !exame.value
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center">{exame.value.exame.descricao_exame}</SttHeading>

                        <SttExpansionPanel
                            title={strings.paciente}
                            children={
                                <div>
                                    <SttTextItem key="1" title={strings.nome} content={exame.value.exame.nome_paciente} />
                                    <SttTextItem key="3" title={strings.sexo} content={exame.value.exame.sexo_paciente} />
                                    <SttTextItem key="2" title={strings.dataNascimento} content={exame.value.exame.data_nascimento_paciente_formatada} />
                                    {exame.value.exame.cartao_sus_paciente && <SttTextItem key="7" title={strings.cns} content={exame.value.exame.cartao_sus_paciente} />}
                                    {exame.value.indicacao && <SttTextItem key="4" title={strings.peso} content={`${exame.value.indicacao.peso_paciente} kg`} />}
                                    {exame.value.indicacao && <SttTextItem key="5" title={strings.altura} content={`${exame.value.indicacao.altura_paciente} cm`} />}
                                    <SttTextItem key="6" title={strings.idade} content={exame.value.exame.idade_paciente} />
                                    {
                                        exame.value.indicacao?.peso_paciente && exame.value.indicacao?.altura_paciente && exame.value.exame.data_nascimento &&
                                        <SttIMC values={{
                                            peso: exame.value.indicacao.peso_paciente,
                                            altura: exame.value.indicacao.altura_paciente,
                                            dataNascimento: exame.value.exame.data_nascimento,
                                        }} strings={strings} />
                                    }
                                </div>
                            }
                        />

                        <SttExpansionPanel
                            title={strings.dadosExame}
                            children={
                                <div>
                                    <SttTextItem key="1" title={strings.modalidade} content={exame.value.exame.descricao_modalidade} />
                                    <SttTextItem key="2" title={strings.dataExame} content={`${exame.value.exame.data_exame} ${global.gConfig.sufixo_data_hora}`} />
                                    <SttTextItem key="3" title={strings.protocolo} content={exame.value.exame.protocolo_rctm} />
                                    <SttTextItem key="4" title={strings.requisicao} content={exame.value.exame.requisicao} />
                                    <SttTextItem key="5" title={strings.instituicao} content={exame.value.exame.nome_instituicao} />
                                    <SttTextItem key="6" title={strings.medicoSolicitante} content={exame.value.exame.nome_medico_solicitante} />
                                    {exame.value.exame.nome_medico_executor && <SttTextItem key="7" title={strings.medicoExecutor} content={exame.value.exame.nome_medico_executor} />}
                                </div>
                            }
                        />

                        {
                            (exame.value.exame.observacao_tecnica || exame.value.indicacao) &&
                            <SttExpansionPanel
                                title={strings.outrasInformacoes}
                                children={
                                    <div>
                                        {exame.value.exame.observacao_tecnica && <SttTextItem key="1" title={strings.observacaoTecnica} content={exame.value.exame.observacao_tecnica} />}
                                        {exame.value.indicacao.descricacao_tabagismo && <SttTextItem key="2" title={strings.tabagismo} content={exame.value.indicacao.descricacao_tabagismo} />}
                                        {
                                            (exame.value.indicacao.tabagismo === TABAGISMO.FUMANTE || exame.value.indicacao.tabagismo === TABAGISMO.EX_FUMANTE) &&
                                            <>
                                                <SttTextItem key="2.1" title={strings.cigarrosDia} content={exame.value.indicacao.cigarros_dia} />
                                                <SttTextItem key="2.2" title={strings.anosFuma} content={exame.value.indicacao.anos_tabagismo} />
                                            </>
                                        }
                                        {exame.value.indicacao.tabagismo === TABAGISMO.EX_FUMANTE && <SttTextItem key="2.3" title={strings.anosParouFumo} content={exame.value.indicacao.anos_parou_fumar} />}

                                        <SttTextItem key="3" title={strings.etilismo} content={exame.value.indicacao.etilismo} />

                                        {comorbidades.value.length > 0 && <SttTextItem key="4" title={strings.comorbidades} content={comorbidades.value.join(', ')} />}
                                        {exame.value.indicacao.motivo_exame && <SttTextItem key="5" title={strings.motivoExame} content={exame.value.indicacao.motivo_exame} />}
                                        {exame.value.indicacao.resumo_historia_clinica && <SttTextItem key="6" title={strings.observacoes} content={exame.value.indicacao.resumo_historia_clinica} />}
                                        {exame.value.indicacao.hmf && <SttTextItem key="7" title={strings.hmf} content={exame.value.indicacao.hmf} />}
                                        {exame.value.indicacao.hmp && <SttTextItem key="8" title={strings.hmp} content={exame.value.indicacao.hmp} />}
                                        {exame.value.indicacao.medicamentos_em_uso && <SttTextItem key="9" title={strings.medicamentosUso} content={exame.value.indicacao.medicamentos_em_uso} />}
                                    </div>
                                }
                            />
                        }

                        {
                            !exame.value.exame.valido &&
                            <SttNotification severity="info" className={classes.notification}>
                                <SttAlertTitle>{strings.exameInvalidado}</SttAlertTitle>
                                <div className={classes.notificationContent}>
                                    <span>{`${strings.motivo}: ${exame.value.exame.motivo_invalidacao}`}</span>
                                    <span>{`${strings.responsavel}: ${exame.value.exame.responsavel_invalidacao}`}</span>
                                    <span>{`${strings.data}: ${exame.value.exame.data_invalidacao} ${global.gConfig.sufixo_data_hora}`}</span>
                                </div>
                            </SttNotification>
                        }

                        {
                            (temPermissaoRBAC(usuario, [PERMISSOES.VISUALIZAR_LAUDO, PERMISSOES.LAUDAR_EXAME_FABRICA, PERMISSOES.LAUDAR_EXAME])) && exame.value.laudo &&
                            <SttExpansionPanel
                                title={strings.laudo}
                                children={
                                    <div dangerouslySetInnerHTML={{ __html: exame.value.laudo }}></div>
                                }
                            />
                        }
                        {
                            temPermissaoRBAC(usuario, [PERMISSOES.VISUALIZAR_IMAGEM, PERMISSOES.LAUDAR_EXAME_FABRICA, PERMISSOES.LAUDAR_EXAME]) &&
                            <Imagens idExame={idExame} />
                        }
                        {
                            exame.value.exame.valido &&
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            imprimirFn(idExame)
                                        }}
                                        nomarginleft="true"
                                    >
                                        {strings.imprimir}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            imprimirProtocoloFn(exame.value.exame)
                                        }}
                                    >
                                        {strings.protocolo}
                                    </SttButton>
                                    {
                                        exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO && (
                                            permissaoBtns.value[1] || (permissaoBtns.value[1] === undefined && temPermissaoRBAC(usuario, PERMISSOES.PRIORIZAR_EXAME))
                                        ) &&
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => priorizar.value = true}
                                        >
                                            {strings.priorizar}
                                        </SttButton>
                                    }
                                    {
                                        exame.value.exame.valido && (
                                            ((permissaoBtns.value[0] || (permissaoBtns.value[0] === undefined && temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME))) && exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) ||
                                            (temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME_LAUDADO) && exame.value.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)
                                        ) &&
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => invalidar.value = true}
                                        >
                                            {strings.invalidar}
                                        </SttButton>
                                    }
                                    {
                                        exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO && temPermissaoRBAC(usuario, PERMISSOES.TROCAR_REDE) &&
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => trocarRede.value = true}
                                        >
                                            {strings.trocarRede}
                                        </SttButton>
                                    }
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            imprimirTermoFn(exame.value.exame)
                                        }}
                                    >
                                        {strings.imprimirTermo}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        }

                        {
                            exame.value.exame.valido && priorizar.value && exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO && (
                                (permissaoBtns.value[1] || (permissaoBtns.value[1] === undefined && temPermissaoRBAC(usuario, PERMISSOES.PRIORIZAR_EXAME)))
                            ) &&
                            <Priorizar
                                priorizar={priorizar}
                                idExame={idExame.value}
                                idInstituicao={exame.value.exame.id_instituicao}
                                callback={voltar}
                            />
                        }
                        {
                            invalidar.value && exame.value.exame.valido && (
                                ((permissaoBtns.value[0] || (permissaoBtns.value[0] === undefined && temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME))) && exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) ||
                                (temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME_LAUDADO) && exame.value.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)
                            ) &&
                            <Invalidar
                                invalidar={invalidar}
                                idExame={idExame.value}
                                idInstituicao={exame.value.exame.id_instituicao}
                                callback={voltar} />
                        }
                        {
                            exame.value.exame.valido && exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO && temPermissaoRBAC(usuario, PERMISSOES.TROCAR_REDE) &&
                            <TrocarRede
                                exame={[idExame.value]}
                                redes={redes.value}
                                trocarRede={trocarRede}
                                redeExameSelecionado={exame.value.exame.id_rede_telemedicina}
                                callback={voltar}
                            />
                        }
                    </>
            }
        </SttContainer>
    )

}

export default Visualizacao;