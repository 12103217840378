export const DESCRITOR = 'descritor';
export const COMPLEMENTO_DESCRITOR = 'complementoDescritor';
export const ESPECIFICACAO = 'especificacao';
export const RESPOSTA_BRONCODILATADOR = 'respostaBroncodilatador';
export const OBSERVACOES = 'observacoes';
export const CID10 = 'cid10';
export const DECS = 'decs';
export const NOME_MEDICO_SOLICITANTE = 'nomeMedicoSolicitante';
export const ID_MEDICO_SOLICITANTE = 'idMedicoSolicitante';
export const ID_PACIENTE = 'idPaciente';
export const ID_EXAME = 'idExame';
export const ID_LAUDO_EMISSAO = 'idLaudoEmissao';
export const DESCRICAO_ESTUDO = 'descricaoEstudo';
export const TIMESTAMP_INICIO = 'timestampInicio';