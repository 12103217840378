import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Routes, Route } from 'react-router-dom';
import Menu from '../componentes/menu';
import Erro401 from './erro/401';
import { SttCircularProgress, SttCacheManager } from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import { PERMISSOES } from '../common/Constants';
import ProtectedRoute from './protectedRoute';
import Pendencias from './pendencias';
import Solicitacao from './solicitacao';
import EnvioImagens from './envio-imagens';
import Exames from './exame';
import Administrativo from './administrativo';
import Laudo from './laudo';
import usuario from '../signals/usuario';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Interno = () => {
    const classes = useStyles();
    useSignals();

    return (
        <div>
            {
                usuario.value.autenticado
                    ?
                    <div className={classes.wrapper}>
                        <Menu />
                        <Routes>
                            <Route path="/nao-autorizado" exact element={<Erro401 />} />
                            <Route path="/" exact element={<Pendencias />} />
                            <Route path="/solicitacao" exact element={<ProtectedRoute component={Solicitacao} permissao={PERMISSOES.SOLICITAR_EXAME} />} />
                            <Route path="/administrativo" exact element={<ProtectedRoute component={Administrativo} permissao={PERMISSOES.ADMINISTRATIVO} />} />
                            <Route path="/imagens" exact element={<ProtectedRoute component={EnvioImagens} permissao={[PERMISSOES.VISUALIZAR_SOLICITACAO, PERMISSOES.CRIAR_EXAME]} />} />
                            <Route path="/exames/*" element={<ProtectedRoute component={Exames} permissao={PERMISSOES.VISUALIZAR_EXAME} />} />
                            <Route path="/laudo" exact element={<ProtectedRoute component={Laudo} permissao={PERMISSOES.LAUDAR_EXAME_FABRICA} />} />
                        </Routes>
                    </div>
                    :
                    <>
                        <SttCacheManager config={global.gConfig} onSync={loaded => {
                            usuario.value = { ...Cache.getUserInfo(), autenticado: loaded };
                        }} hasExternalToken={true} />
                        <div className={classes.loading}>
                            <SttCircularProgress />
                        </div>
                    </>
            }
        </div>
    );

};

export default Interno;
