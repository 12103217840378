import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import { temPermissaoRBAC } from '../../secutity/rbac';
import {
    SttLoading,
    SttGrid,
    SttButton,
    SttModal,
    SttText,
    SttAutocomplete,
    SttInput,
    SttTranslateHook
} from '@stt-componentes/core';
import { PERMISSOES, MODALIDADE } from '../../common/Constants';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../signals/usuario';
import alerta from '../../signals/alerta';
import { batch } from '@preact/signals-react';
import { listar } from '../../signals/exame';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));

const Invalidacao = ({ invalidar, idExame, idInstituicao, idRede, callback }) => {
    const classes = useStyles();
    useSignals();

    const { strings } = useContext(SttTranslateHook.I18nContext);

    const motivos = useSignal(null);
    const motivo = useSignal(null);
    const observacao = useSignal('');
    const invalidando = useSignal(false);
    const formValido = useSignal(false);

    useSignalEffect(() => {
        if (!motivos.value) {
            let params = { modalidade: MODALIDADE.SIGLA };
            if (idRede) {
                params.rede = idRede;
            }
            axios.get(`${global.gConfig.url_base_exames}/motivo-invalidacao`, { params, headers: getHeaders() })
                .then((response) => {
                    // Se não for médico, a seleção do motivo não é obrigatória pelo usuário
                    // É atribuído um motivo padrão
                    if (!temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME)) {
                        const motivosTecnico = response.data.filter(m => m.motivo_padrao_tecnico);
                        motivos.value = motivosTecnico;
                        if (motivosTecnico.length) {
                            handleChangeMotivo(null, motivosTecnico[0]);
                        }
                        return;
                    }
                    motivos.value = response.data;
                })
                .catch(err => console.log(err));
        }
    });

    useSignalEffect(() => {
        if (!temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME)) {
            // Técnicos e administradores não podem laudar e só precisam da observação textual
            if (observacao.value.trim()) {
                formValido.value = true;
                return;
            }
            formValido.value = false;
        } else {
            // Laudadores precisam selecionar o motivo
            if (motivo.value) {
                formValido.value = true;
                return;
            }
            formValido.value = false;
        }
    });

    const fecharInvalidacao = () => {
        batch(() => {
            motivo.value = null;
            observacao.value = '';
            invalidar.value = false;
        });
    }

    const handleChangeMotivo = (event, novoMotivo) => {
        motivo.value = novoMotivo;
    }

    const handleChangeObservacao = (event) => {
        observacao.value = event.target.value;
    }

    const invalidarExame = () => {
        invalidando.value = true;
        const payload = {
            idExame: idExame,
            instituicao: idInstituicao,
            motivo: motivo.value?.id,
            observacao: observacao.value.trim()
        };
        axios.post(`${global.gConfig.url_base_exames}/invalidar`, payload, { headers: getHeaders() })
            .then(() => {
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.sucesso,
                    type: 'success',
                    message: strings.exameInvalidado,
                    options: [{
                        title: strings.ok,
                        onClick: () => {
                            if (callback) {
                                callback();
                            }
                            fecharInvalidacao();
                            listar.value = true;
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }],
                    onClose: () => {
                        fecharInvalidacao();
                        listar.value = true;
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            })
            .catch(err => {
                console.log(err);

                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.erro,
                    type: 'error',
                    message: strings.erroInvalidarExame,
                    options: [{
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            })
            .finally(() => {
                invalidando.value = false;
            });
    }

    return (
        <>
            <SttModal
                title={strings.invalidar}
                open={invalidar.value}
                outModalClose={fecharInvalidacao}
                iconClose={fecharInvalidacao}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                {
                                    temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME)
                                        ?
                                        <SttText variant="body1">{strings.textoInvalidar}</SttText>
                                        :
                                        <SttText variant="body1">{strings.textoInvalidarExameObs}</SttText>
                                }
                            </SttGrid>
                            <SttGrid item xs={12}>
                                {
                                    temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME)
                                        ?
                                        <SttAutocomplete
                                            inputprops={{
                                                label: strings.motivo,
                                                required: true
                                            }}
                                            getOptionLabel={option => option.descricao}
                                            options={motivos.value}
                                            value={motivo.value}
                                            onChange={handleChangeMotivo}
                                        />
                                        :
                                        <SttInput
                                            value={observacao.value}
                                            label={strings.observacao}
                                            required
                                            onChange={handleChangeObservacao}
                                            multiline
                                            minRows={4}
                                        />
                                }
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={!formValido.value}
                            onClick={() => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: true,
                                    title: strings.atencao,
                                    type: 'confirmation',
                                    message: strings.confirmarInvalidarExame,
                                    options: [
                                        {
                                            title: strings.sim,
                                            onClick: () => {
                                                invalidarExame();
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                };
                                            }
                                        },
                                        {
                                            title: strings.nao,
                                            onClick: () => {
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                };
                                            }
                                        }
                                    ],
                                    onClose: () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        };
                                    }
                                };
                            }}
                        >
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharInvalidacao}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <div className={classes.carregando}>
                <SttLoading
                    open={invalidando.value}
                    text={strings.invalidandoExame}
                />
            </div>
        </>
    );
};

Invalidacao.propTypes = {
    idExame: PropTypes.number.isRequired,
    invalidar: PropTypes.bool.isRequired,
    setInvalidar: PropTypes.func.isRequired,
    idRede: PropTypes.number,
    callback: PropTypes.func
};

export default Invalidacao;