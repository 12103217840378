import React, { useContext } from 'react';
import {
    SttDateTimePicker,
    SttGrid,
    SttInput,
    SttTranslateHook
} from '@stt-componentes/core';
import { FIELDS } from './fieldNames';
import { Field, FastField, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Equipamento from '../informacoes-gerais/equipamento';
import { temPerfilRBAC } from '../../../secutity/rbac';
import { PERFIL } from '../../../common/Constants';
import { useSignals } from '@preact/signals-react/runtime';
import usuario from '../../../signals/usuario';

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 0,
        marginTop: theme.spacing(1)
    }
}));

const DadosGerais = ({ equipamentos }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { errors } = useFormikContext();
    useSignals();

    return (
        <SttGrid container spacing={1} className={classes.container}>
            <SttGrid item xs={12} md={6}>
                <FastField name={FIELDS.DATA_TESTE}>
                    {({
                        field: { value },
                        form: { setFieldValue, setFieldError, setFieldTouched },
                        meta
                    }) => {
                        let erroAtual;
                        if (errors[FIELDS.DATA_TESTE]) {
                            erroAtual = errors[FIELDS.DATA_TESTE];
                        }
                        return (
                            <SttDateTimePicker
                                required={true}
                                value={value}
                                label={strings.dataHoraTeste}
                                error={meta.touched && meta.error ? true : false}
                                onChange={item => setFieldValue(FIELDS.DATA_TESTE, item || null)}
                                onError={error => setFieldError(FIELDS.DATA_TESTE, meta.error || error)}
                                onBlur={() => setFieldTouched(FIELDS.DATA_TESTE, true)}
                                helperText={meta.touched && meta.error ? meta.error : undefined}
                            />
                        );
                    }}
                </FastField>
            </SttGrid>
            <SttGrid item xs={12} md={6}>
                <Field name={FIELDS.EQUIPAMENTO}>
                    {(props) => (
                        <Equipamento equipamentos={equipamentos} {...props} />
                    )}
                </Field>
            </SttGrid>
            {
                !temPerfilRBAC(usuario, PERFIL.MEDICO_SOLICITANTE) &&
                <SttGrid item xs={12} sm={12}>
                    <FastField name={FIELDS.OBSERVACAO}>
                        {({
                            field,
                            meta
                        }) => (
                            <SttInput
                                multiline
                                minRows={5}
                                {...field}
                                label={strings.observacoes}
                                error={meta.touched && meta.error ? true : false}
                                helperText={meta.touched && meta.error ? meta.error : undefined}
                            />
                        )}
                    </FastField>
                </SttGrid>
            }
        </SttGrid>
    );
}

export default DadosGerais;