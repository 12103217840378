import React, { memo, useContext } from 'react';
import { FastField, useFormikContext } from 'formik';
import { COMPLEMENTO_DESCRITOR, DESCRITOR, ESPECIFICACAO, OBSERVACOES, RESPOSTA_BRONCODILATADOR } from './fieldNames';
import {
    SttRadioGroup,
    SttHeading,
    SttFormControl,
    SttFormControlLabel,
    SttRadioButton,
    SttInput,
    SttTranslateHook,
} from '@stt-componentes/core';
import { DESCRITOR_LAUDO, ESPECIFICACAO_LAUDO, RESPOSTA_BRONCO } from '../../common/Constants';

const AbaTextual = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { values, setFieldValue } = useFormikContext();

    return (
        <>
            <SttHeading required variant="h4" color="primary">{strings.descritor}</SttHeading>
            <FastField name={DESCRITOR}>
                {({
                    field,
                    meta,
                }) => (
                    <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined" fullWidth>
                        <SttRadioGroup
                            onChange={(e, item) => {
                                setFieldValue(DESCRITOR, item);
                                if (DESCRITOR_LAUDO[item].especificacao) {
                                    setFieldValue(ESPECIFICACAO, ESPECIFICACAO_LAUDO.LEVE.value);
                                } else {
                                    setFieldValue(ESPECIFICACAO, null);
                                }
                                setFieldValue(COMPLEMENTO_DESCRITOR, '');
                            }
                            }>
                            {
                                Object.entries(DESCRITOR_LAUDO).map(([key, value], index) => (
                                    <SttFormControlLabel
                                        {...field}
                                        key={index}
                                        control={
                                            <SttRadioButton
                                                type="radio"
                                                value={value.value}
                                                color="primary"
                                                checked={field.value === value.value}
                                            />
                                        }
                                        label={value.label}
                                    />
                                ))
                            }
                            {
                                DESCRITOR_LAUDO[values[DESCRITOR]].complemento &&
                                <FastField name={COMPLEMENTO_DESCRITOR}>
                                    {({
                                        field,
                                        meta
                                    }) => (
                                        <SttInput
                                            {...field}
                                            label={strings.complemento}
                                            required={true}
                                            error={meta.touched && meta.error ? true : false}
                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                        />
                                    )}
                                </FastField>
                            }
                        </SttRadioGroup>
                    </SttFormControl>
                )}
            </FastField>

            {
                DESCRITOR_LAUDO[values[DESCRITOR]].especificacao &&
                <>
                    <br />
                    <SttHeading required variant="h4" color="primary">{strings.especificacao} - {DESCRITOR_LAUDO[values[DESCRITOR]].label}</SttHeading>
                    <FastField name={ESPECIFICACAO}>
                        {({
                            field,
                            meta,
                        }) => (
                            <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined" fullWidth>
                                <SttRadioGroup row
                                    onChange={(e, item) => {
                                        console.log(item)
                                        setFieldValue(ESPECIFICACAO, item);
                                    }
                                    }>
                                    {
                                        Object.entries(ESPECIFICACAO_LAUDO).map(([key, value], index) => (
                                            <SttFormControlLabel
                                                {...field}
                                                key={index}
                                                control={
                                                    <SttRadioButton
                                                        type="radio"
                                                        value={value.value}
                                                        color="primary"
                                                        checked={field.value === value.value}
                                                    />
                                                }
                                                label={value.label}
                                            />
                                        ))
                                    }
                                </SttRadioGroup>
                            </SttFormControl>
                        )}
                    </FastField>
                </>
            }

            <br />

            <SttHeading required variant="h4" color="primary">{strings.respostaBroncodilatador}</SttHeading>
            <FastField name={RESPOSTA_BRONCODILATADOR}>
                {({
                    field,
                    meta,
                }) => (
                    <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined" fullWidth>
                        <SttRadioGroup row
                            onChange={(e, item) => {
                                console.log(item)
                                setFieldValue(RESPOSTA_BRONCODILATADOR, item);
                            }
                            }>
                            {
                                Object.entries(RESPOSTA_BRONCO).map(([key, value], index) => (
                                    <SttFormControlLabel
                                        {...field}
                                        key={index}
                                        control={
                                            <SttRadioButton
                                                type="radio"
                                                value={value.value}
                                                color="primary"
                                                checked={field.value === value.value}
                                            />
                                        }
                                        label={value.label}
                                    />
                                ))
                            }
                        </SttRadioGroup>
                    </SttFormControl>
                )}
            </FastField>

            <br />

            <SttHeading variant="h4" color="primary">{strings.observacoes}</SttHeading>
            <FastField name={OBSERVACOES}>
                {({
                    field,
                    meta
                }) => (
                    <SttInput
                        multiline
                        minRows={5}
                        {...field}
                        error={meta.touched && meta.error ? true : false}
                        helperText={meta.touched && meta.error ? meta.error : undefined}
                    />
                )}
            </FastField>
        </>
    )
}

export default memo(AbaTextual);