import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    SttNotification,
    SttLoading,
    SttGrid,
    SttButton,
    SttTranslateHook
} from '@stt-componentes/core';
import Laudo from './index';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { batch } from '@preact/signals-react';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    botao: {
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center'
    }
}));

const Visualizacao = ({ id }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const idExame = useSignal(null);
    const carregandoExame = useSignal(true);
    const msgCarregando = useSignal(strings.carregandoExame);
    const nenhumExameLaudo = useSignal(false);
    const proximoExame = useSignal(true);
    const erro = useSignal(false);

    const notificar = (mensagem) => {
        batch(() => {
            msgCarregando.value = mensagem;
            if (!carregandoExame.value) {
                carregandoExame.value = true;
            }
        });
    }

    const callbackProximoExame = () => {
        batch(() => {
            msgCarregando.value = strings.carregandoExame;
            erro.value = false;
            idExame.value = null;
            if (!carregandoExame) {
                carregandoExame.value = true;
            }
            proximoExame.value = true;
        });
    }

    const callbackExameCarregado = () => {
        carregandoExame.value = false;
    }

    useSignalEffect(() => {
        if (proximoExame.value) {
            axios.get(`${global.gConfig.url_base_espiro}/laudo/fabrica?rede=${id}`, { headers: getHeaders(), timeout: 15000 })
                .then((response) => {
                    const { data } = response;
                    if (!data.data.idExame) {
                        nenhumExameLaudo.value = true;
                        callbackExameCarregado();
                        return;
                    }
                    idExame.value = data.data.idExame;
                })
                .catch(err => {
                    callbackExameCarregado();
                    erro.value = true;
                    console.error(err);
                })
                .finally(() => {
                    // Redefine variável de controle
                    proximoExame.value = false;
                });
        }
    });

    return (
        <>
            <div className={classes.carregando}>
                <SttLoading
                    open={carregandoExame.value}
                    text={msgCarregando.value}
                />
            </div>

            {
                (erro.value &&
                    <SttGrid container spacing={3}>
                        <SttGrid item xs={12}>
                            <SttNotification severity="error">
                                {strings.erroSortearExame}
                            </SttNotification>
                            <div className={classes.botao}>
                                <SttButton
                                    nomarginleft="true"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => callbackProximoExame()}>
                                    {strings.tentarNovamente}
                                </SttButton>
                            </div>
                        </SttGrid>
                    </SttGrid>) ||
                (idExame.value ?
                    <Laudo
                        idExame={parseInt(idExame.value)}
                        idRede={id}
                        callbackProximoExame={callbackProximoExame}
                        callbackExameCarregado={callbackExameCarregado}
                        notificar={notificar}
                    />
                    :
                    nenhumExameLaudo.value &&
                    <SttNotification severity="info">
                        {strings.nenhummExameParaLaudo}
                    </SttNotification>)
            }
        </>
    )

}

Visualizacao.propTypes = {
    id: PropTypes.number.isRequired,
};

export default Visualizacao;