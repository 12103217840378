import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import StickyBox from "react-sticky-box";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    SttGrid,
    SttButton,
    SttHidden,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import Laudo from './laudo';
import Imagens from '../anexos/imagens';
import Invalidar from '../exame/invalidar';
import { useMoment } from '../../hooks';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import alerta from '../../signals/alerta';
import laudar from '../../signals/laudo';

const useStyles = makeStyles(theme => ({
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    modalBody: {
        overflow: 'hidden'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const ConteinerLaudo = ({ idExame, idRede, callbackProximoExame, callbackExameCarregado, notificar }) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const location = useLocation();
    const moment = useMoment();
    const exame = useSignal(null);
    const contextoFabricalaudo = useSignal(location.pathname === '/laudo');
    const invalidar = useSignal(false);

    const handleCloseAlerta = () => {
        if (contextoFabricalaudo.value) {
            callbackProximoExame();
        } else {
            laudar.value = false;
        }
    }

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_espiro}/laudo/${idExame}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    let { data } = response.data;
                    let dataMoment = moment(data.exame.data_nascimento_paciente);
                    if (dataMoment.isValid()) {
                        let now = moment();
                        let dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
                        let idade = moment.duration(now.diff(dataMomentFormatada));
                        let anos = idade.years();
                        let meses = idade.months();
                        let dias = idade.days();
                        data.exame.data_nascimento_paciente_formatada += ` (${anos} ano(s), ${meses} mes(es) e ${dias} dia(s))`
                    }
                    exame.value = data;
                    if (contextoFabricalaudo.value) {
                        callbackExameCarregado();
                    }
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.erroDesconhecido;
                let arrMensagem = [];
                if (response) {
                    const { data } = response;
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    msg = arrMensagem.join('\n');
                }

                alerta.value = {
                    title: strings.erro,
                    message: msg,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                                handleCloseAlerta();
                            }
                        }
                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                        handleCloseAlerta();
                    }
                };
            });

        return () => {
            laudar.value = false;
            exame.value = null;
            cancelarEmissao();
        }
    }, []);

    const voltar = () => {
        // Controle de componentes. Volta para tela anterior
        laudar.value = false;

        // Cancela emissão do laudo
        cancelarEmissao();
    }

    const cancelarEmissao = () => {
        axios.post(`${global.gConfig.url_base_espiro}/laudo/cancelar`, { idExame }, { headers: getHeaders() })
            .catch(err => console.log(err));
    }

    const ignorarExame = () => {
        exame.value = null;
        notificar(strings.ignorandoExame);
        // Insere exame ignorado
        axios.post(`${global.gConfig.url_base_espiro}/laudo/ignorar-exame`, { idExame }, { headers: getHeaders(), timeout: 15000 })
            .then((resposta) => {
                // Carrega o próximo exame
                callbackProximoExame();
            })
            .catch(err => {
                console.log(err);
                alerta.value = {
                    title: strings.erro,
                    message: strings.erroIgnorarExame,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                                handleCloseAlerta();
                            }
                        }
                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                        handleCloseAlerta();
                    }
                };
            });
    }

    return (
        <>
            <div className={classes.carregando}>
                <SttLoading
                    open={!exame.value && !contextoFabricalaudo.value}
                    text={strings.carregandoExame}
                />
            </div>
            {
                exame.value &&
                <>
                    <SttGrid container spacing={1}>
                        <SttGrid item xs={12} className={classes.wrapperBotao}>
                            {
                                <SttButton
                                    variant="contained"
                                    danger={+true}
                                    onClick={() => invalidar.value = true}
                                >
                                    {strings.invalidar}
                                </SttButton>
                            }

                            {
                                !contextoFabricalaudo.value &&
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={voltar}
                                >
                                    {strings.voltar}
                                </SttButton>
                            }

                            {
                                contextoFabricalaudo.value && global.gConfig.com_botao_proximo_exame &&
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={ignorarExame}
                                >
                                    {strings.proximoExame}
                                </SttButton>
                            }
                        </SttGrid>
                    </SttGrid>
                    <SttGrid container spacing={1}>
                        <SttHidden mdUp>
                            <SttGrid item xs={12}>
                                <Imagens idExame={idExame} />
                            </SttGrid>
                            <SttGrid item xs={12}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} laudar={laudar} />
                            </SttGrid>
                        </SttHidden>
                        <SttHidden only={['xs', 'sm']}>
                            <SttGrid item xs={6}>
                                <StickyBox>
                                    <Imagens idExame={idExame} />
                                </StickyBox>
                            </SttGrid>
                            <SttGrid item xs={6}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} laudar={laudar} />
                            </SttGrid>
                        </SttHidden>
                    </SttGrid>
                </>
            }
            {
                invalidar.value &&
                <Invalidar
                    idExame={idExame}
                    invalidar={invalidar}
                    idRede={idRede}
                    callback={callbackProximoExame}
                />
            }
        </>
    );
};

ConteinerLaudo.propTypes = {
    idExame: PropTypes.number.isRequired,
    callbackProximoExame: PropTypes.func,
    callbackExameCarregado: PropTypes.func
};

export default ConteinerLaudo;