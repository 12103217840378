import * as yup from 'yup';
import { PERMISSOES } from '../../../common/Constants';
import { temPermissaoRBAC } from '../../../secutity/rbac';
import { FIELDS } from './fieldNames';

const validationDadosGerais = (strings, usuario) => {
    if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
        return yup.object().shape({
            [FIELDS.EQUIPAMENTO]: yup.object().shape({
                id: yup.number(),
                id_humano: yup.string(),
                id_modalidade: yup.number(),
                tipo: yup.string(),
                id_instituicao: yup.number()
            })
                .nullable()
                .required(strings.campoObrigatorio),
            [FIELDS.DATA_TESTE]: yup.date()
                .typeError(strings.dataInvalida)
                .max(new Date(), strings.erroDataFutura)
                .nullable()
                .required(strings.campoObrigatorio)

        });
    }
    return yup.object().shape({});
}

export { validationDadosGerais };