import {
    DESCRICAO_ESTUDO,
    DECS,
    ID_EXAME,
    ID_LAUDO_EMISSAO,
    TIMESTAMP_INICIO,
    ID_PACIENTE,
    ID_MEDICO_SOLICITANTE,
    NOME_MEDICO_SOLICITANTE,
    ESPECIFICACAO,
    RESPOSTA_BRONCODILATADOR,
    DESCRITOR,
    COMPLEMENTO_DESCRITOR,
} from './fieldNames';
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';
import { DESCRITOR_LAUDO, RESPOSTA_BRONCO } from '../../common/Constants';

export const values = (idExame, idLaudoEmissao, idPaciente, idSolicitante, nomeSolicitante) => {
    let val = {
        [ID_EXAME]: idExame,
        [ID_LAUDO_EMISSAO]: idLaudoEmissao,
        [ID_PACIENTE]: idPaciente,
        [ID_MEDICO_SOLICITANTE]: idSolicitante,
        [NOME_MEDICO_SOLICITANTE]: nomeSolicitante,
        [DESCRICAO_ESTUDO]: 'Exame de Espirometria',
        [TIMESTAMP_INICIO]: new Date().getTime(),
        [CID_10]: [],
        [DECS]: [],
        [DESCRITOR]: DESCRITOR_LAUDO.NORMAL.value,
        [ESPECIFICACAO]: null,
        [RESPOSTA_BRONCODILATADOR]: RESPOSTA_BRONCO.NEGATIVA.value,
        [COMPLEMENTO_DESCRITOR]: ''
    };

    // TODO: tratar para relaudo

    return val;
}