import React, { useEffect, useContext } from 'react';
import {
    SttGrid,
    SttFileSelect,
    SttButton,
    SttTranslateHook
} from '@stt-componentes/core';
import { Field, FieldArray, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useSignals, useSignal } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    divAnexo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        minHeight: '250px'
    },
    imgAtual: {
        maxHeight: '320px',
        maxWidth: '320px',
        margin: 'auto'
    },
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },

    botaoMenos: {
        minWidth: 0
    },

    divExternaAnexos: {
        maxHeight: '500px',
        overflow: 'auto'
    },
    container: {
        marginBottom: 0,
        marginTop: theme.spacing(1)
    }
}));

const Anexos = () => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { setFieldValue, values } = useFormikContext();
    useSignals();

    const anexos = useSignal([]);

    const uploadFile = (event, indice, removerAnexo) => {
        //Caso o anexo tenha sido excluída, remove a mesma do array de anexos no formulário e retira do array de anexos
        if (!event) {
            if (anexos.value.length > 1) {
                removerAnexo(indice);
            } else {
                setFieldValue(`anexo.${(indice)}`, {});
            }

            anexos.value = anexos.value.filter((anexo, i) => i !== indice);
        } else {
            if (event.target.files[0]) {
                //Trata nome e blob do anexo para exibição no carousel
                lerAnexoBase64(event.target.files[0]);
                //Seta o valor do anexo no campo correspondente do formulário
                setFieldValue(`anexo.${(indice)}`, event.target.files[0]);
            }
        }
    };

    useEffect(() => {
        if (anexos.length === 0 && values['anexo']) {
            //Trata nome e blob do anexo para exibição no carousel
            values['anexo'].forEach(file => {
                if (file && file.name) {
                    lerAnexoBase64(file);
                }
            });
        }
    }, []);

    const lerAnexoBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            anexos.value = [
                ...anexos.value,
                {
                    nome: file.name,
                    anexo: reader.result,
                    ehImagem: file.type !== 'application/pdf'
                }
            ];
        };
    }

    const validarAnexo = (value) => {
        let error;
        if (!value || !(value instanceof File)) {
            error = strings.campoObrigatorio;
        } else if (!['image/png', 'image/bmp', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(value.type)) {
            error = strings.anexoInvalido;
        }
        return error;
    }

    return (
        <SttGrid container spacing={2} justifyContent="center" className={classes.container}>
            <SttGrid item xs={12} md={6}>
                <FieldArray
                    name={'anexo'}
                    render={({ remove, push }) => (
                        <>
                            <div className={classes.divExternaAnexos}>
                                {values['anexo'] && values['anexo'].length > 0 &&
                                    values['anexo'].map((anexo, indice) => (
                                        <div key={indice}>
                                            <Field name={`anexo.${(indice)}`} validate={validarAnexo}>
                                                {({
                                                    field: { name, value, onBlur },
                                                    meta
                                                }) => (
                                                    <>
                                                        <SttFileSelect
                                                            labelInput={strings.anexo}
                                                            onFileChange={(event) => uploadFile(event, indice, remove)}
                                                            file={value}
                                                            inputprops={{
                                                                name: name,
                                                                label: strings.anexo,
                                                                required: true,
                                                                value: value && value.name,
                                                                error: meta.touched && meta.error ? meta.error : undefined
                                                            }}
                                                            accept={["image/jpg", "image/jpeg", "image/png", "image/bmp", "application/pdf"]}
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </div>
                                    ))}
                            </div>

                            <SttButton
                                className={classes.botaoMais}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    push({});
                                }}
                            >
                                +
                            </SttButton>
                            <SttButton
                                className={classes.botaoMenos}
                                variant="contained"
                                disabled={values['anexo'] && values['anexo'].length == 1}
                                onClick={() => {
                                    if (values['anexo'].length > 1) {
                                        let indice = values['anexo'].length - 1;
                                        remove(indice);
                                        anexos.value = anexos.value.filter((anexo, i) => i !== indice);
                                    }
                                }}
                                color="primary">
                                -
                            </SttButton>
                        </>
                    )}
                />
            </SttGrid>

            <SttGrid item xs={12} md={6}>
                <Carousel statusFormatter={(current, total) => `Imagem ${current} de ${total}`}>
                    {
                        anexos.value.filter(a => a.ehImagem).map((anexo, indice) => {
                            return (
                                <div className={classes.divAnexo} key={indice}>
                                    <img src={anexo.anexo} className={classes.imgAtual} />
                                    <p className="legend">{anexo.nome}</p>
                                </div>
                            );
                        })
                    }
                </Carousel>
            </SttGrid>
        </SttGrid>
    );
}

export default Anexos;