import * as yup from 'yup';
import { DESCRITOR_LAUDO } from '../../common/Constants';
import {
    COMPLEMENTO_DESCRITOR,
    DESCRITOR,
    ESPECIFICACAO,
    OBSERVACOES,
    RESPOSTA_BRONCODILATADOR
} from './fieldNames';

export default (strings, lesoes) => {
    let schema = yup.object().shape({
        [DESCRITOR]: yup
            .string()
            .trim()
            .nullable()
            .required(strings.campoObrigatorio),
        [ESPECIFICACAO]: yup
            .string()
            .nullable()
            .when(DESCRITOR, {
                is: (val) => val && DESCRITOR_LAUDO[val].especificacao,
                then: yup
                    .string()
                    .nullable()
                    .required(strings.campoObrigatorio)
            }),
        [COMPLEMENTO_DESCRITOR]: yup
            .string()
            .nullable()
            .when(DESCRITOR, {
                is: (val) => val && DESCRITOR_LAUDO[val].complemento,
                then: yup
                    .string()
                    .nullable()
                    .required(strings.campoObrigatorio)
            }),
        [RESPOSTA_BRONCODILATADOR]: yup
            .string()
            .trim()
            .nullable()
            .required(strings.campoObrigatorio),
        [OBSERVACOES]: yup
            .string()
            .trim()
            .nullable()
    });

    return schema;
}