export const FIELDS = {
    INFORMACOES_CLINICAS: 'informacoesClinicas',
    TABAGISMO: 'tabagismo',
    TABAGISMO_CIGARROS_DIA: 'tabagismoCigarrosDia',
    TABAGISMO_ANOS_FUMO: 'tabagismoAnosFumo',
    TABAGISMO_PAROU_FUMO: 'tabagismoParouFumo',
    ETILISMO: 'etilismo',
    MOTIVO_EXAME: 'motivoExame',
    RESUMO_HISTORIA: 'resumoHistoria',
    HMF: 'hmf',
    HMP: 'hmp',
    COMORBIDADES: 'comorbidades',
    COMORBIDADES_OUTRAS: 'comorbidadesOutras',
    MEDICAMENTOS_USO: 'medicamentosUso',
};