import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from '../../request';
import axios from 'axios';
import {
    SttLoading,
    SttGrid,
    SttButton,
    SttModal,
    SttAutocomplete,
    SttTranslateHook
} from '@stt-componentes/core';
import alerta from '../../signals/alerta';
import { listar } from '../../signals/exame';
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));

const TrocarRede = ({ redes, trocarRede, exame, redeExameSelecionado, callback }) => {
    useSignals();
    const classes = useStyles();

    const { strings } = useContext(SttTranslateHook.I18nContext);

    const rede = useSignal(null);
    const trocandoRede = useSignal(false);

    const fecharTrocarRede = () => {
        rede.value = null;
        trocarRede.value = false;
    }

    const selecionarRede = (event, novaRede) => {
        rede.value = novaRede;
    }

    const trocarRedeExame = () => {
        trocandoRede.value = true;

        const dados = { exame, rede: rede.value.id };

        axios.put(`${global.gConfig.url_base_exames}/trocar-rede`, dados, { headers: getHeaders() })
            .then((resposta) => {
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.sucesso,
                    type: 'success',
                    message: strings.redeAlterada,
                    options: [{
                        title: strings.ok,
                        onClick: () => {
                            fecharTrocarRede();
                            listar.value = true;
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                            if (callback) {
                                callback();
                            }
                        }
                    }],
                    onClose: () => {
                        fecharTrocarRede();
                        if (callback) {
                            callback();
                        }
                        listar.value = true;
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            })
            .catch(err => {
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.erro,
                    type: 'error',
                    message: strings.erroTrocarRedeExame,
                    options: [{
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            })
            .finally(() => {
                trocandoRede.value = false;
            });
    }

    return (
        <>
            <SttModal
                title={strings.trocarRede}
                open={trocarRede.value}
                outModalClose={fecharTrocarRede}
                iconClose={fecharTrocarRede}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttAutocomplete
                                    inputprops={{
                                        label: strings.novaRede,
                                        required: true
                                    }}
                                    getOptionLabel={option => option?.descricao || ''}
                                    options={redes.filter(r => r.id !== redeExameSelecionado)}
                                    value={rede.value}
                                    onChange={selecionarRede}
                                />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton
                            variant="contained"
                            color="primary"
                            disabled={!rede.value}
                            onClick={() => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: true,
                                    title: strings.atencao,
                                    type: 'confirmation',
                                    message: strings.confirmarTrocarRedeExame,
                                    options: [
                                        {
                                            title: strings.sim,
                                            onClick: () => {
                                                trocarRedeExame();
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                };
                                            }
                                        },
                                        {
                                            title: strings.nao,
                                            onClick: () => {
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                };
                                            }
                                        }
                                    ],
                                    onClose: () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        };
                                    }
                                };
                            }}
                        >
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharTrocarRede}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <div className={classes.carregando}>
                <SttLoading
                    open={trocandoRede.value}
                    text={strings.trocandoRede}
                />
            </div>
        </>
    );
}

export default TrocarRede;